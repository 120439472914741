import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IntegrationSelect from "./components/integration-select";

import "./zero-state-message.scss";
import { Button } from "@transfr-inc/dashboard-components/forms";

export function ZeroStateMessage({ className, onActionClicked, integrations }) {
  const emptyStateText =
    "There are no third party integrations for your organization.";

  return (
    <div className={clsx("zero-state-message", className)}>
      <div className="text">
        <FontAwesomeIcon icon={["fa-light", "swap"]} />
        <span>{emptyStateText}</span>
        <div className="integration-link">
          <IntegrationSelect
            integrations={integrations}
            onSelectIntegration={onActionClicked}
          />
        </div>
      </div>
    </div>
  );
}
