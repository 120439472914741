import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";
import container from "../../../../container";
import "./integration-select.scss";

const IntegrationSelect = ({ integrations, onSelectIntegration }) => {
  const { integrationService } = container;
  const [imageMappedIntegrations, setImageMappedIntegrations] = useState([]);

  useEffect(() => {
    if (integrations?.length) {
      fetchIcons();
    }
  }, [integrations]);

  const fetchIcons = async () => {
    const integrationCopy = [...integrations];
    const updatedIntegrations = await Promise.all(
      integrationCopy.map(async (integration) => {
        const icon = await integrationService.getProviderIconRect(
          integration.id
        );
        return { ...integration, icon };
      })
    );
    setImageMappedIntegrations(updatedIntegrations);
  };

  return (
    <Dropdown
      className="integration-dropdown"
      selected={{}}
      onOptionSelected={onSelectIntegration}
    >
      <DropdownButton className={"integration-dropdown-button"}>
        Add a new integration
      </DropdownButton>
      <DropdownItems>
        {imageMappedIntegrations?.map((integration) => (
          <DropdownItem
            key={integration.id}
            value={integration}
            className={"integration-dropdown-item"}
          >
            <img
              className="provider-image"
              src={integration.icon}
              alt={integration.name}
            />
          </DropdownItem>
        ))}
      </DropdownItems>
    </Dropdown>
  );
};

export default IntegrationSelect;
