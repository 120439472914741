import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import ContainerRoute from "../../components/common/routing/container-route";

import { default as IntegrationsList } from "./list";

export default function Integrations({ responsiveStyles = {} }) {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ContainerRoute
        exact={true}
        path={path}
        component={IntegrationsList}
        responsiveStyles={responsiveStyles}
      ></ContainerRoute>
    </Switch>
  );
}
