import React, { useState, useEffect } from "react";
import clsx from "clsx";

import {
  Column,
  ColumnType,
  SortDirection,
  DataTable,
} from "@transfr-inc/dashboard-components/data-table";

import { MenuOptionSpec } from "@transfr-inc/dashboard-components";

import {
  useSearch,
  SearchBox,
  Button,
} from "@transfr-inc/dashboard-components/forms";

import { ZeroStateMessage } from "./zero-state-message";

import { Role } from "../../../models/role";
import {
  DataTableColumnDefinition,
  getUserDetailPathConfig,
} from "./data-table-column-definition";

import "./trainee-users-tab.scss";
import { RosterModal } from "../roster/roster-modal";
import { useStoreState } from "easy-peasy";

const hiddenOptions = new Set(["edit", "change_password"]);
const disableEditsToAnonUsers = (rowData, menuOptions) => {
  if (rowData?.isAnonymous) {
    const filtered = menuOptions.filter(
      (option) => !hiddenOptions.has(option?.id)
    );
    return filtered;
  }
  return menuOptions;
};

export function TraineeUsersTab({
  data,
  bottomBarOptions,
  actionMenuOptions,
  responsiveStyles = {},
  dataFiltered,
  className = "trainee-users-tab",
}) {
  const { rosteringEnabled } = useStoreState((state) => state.app);

  const breadcrumbLabel = "All Users";
  const columns = [
    ...(bottomBarOptions
      ? [new Column("select", /*title:*/ undefined, ColumnType.SELECTION)]
      : []),
    DataTableColumnDefinition.AVATAR,
    DataTableColumnDefinition.LAST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel })
    ),
    DataTableColumnDefinition.FIRST_NAME(
      getUserDetailPathConfig({ breadcrumbLabel })
    ),
    DataTableColumnDefinition.ORGANIZATION,
    DataTableColumnDefinition.CLASSROOM,
    DataTableColumnDefinition.USERNAME,
    DataTableColumnDefinition.EMAIL,
  ];

  const [stateTrainees, setStateTrainees] = useState(data);
  const [searchActive, setSearchActive] = useState(false);
  const [openRosterModal, setOpenRosterModal] = useState(false);

  const nameSearch = useSearch({
    data: data,
    searchKeys: ["firstName", "lastName", "username"],
  });

  const onResultSelected = (option) => {
    setStateTrainees([option]);
  };

  const onNameTermChange = (term) => {
    term.length > 0 && setSearchActive(true);

    const results = nameSearch.queryData(term);
    setStateTrainees(results);
  };

  useEffect(() => {
    const results = nameSearch.updateData(data);
    setStateTrainees(results);
  }, [data]);

  const zeroStateMessage = (
    <ZeroStateMessage
      role={Role.STUDENT}
      filtered={searchActive || dataFiltered}
    ></ZeroStateMessage>
  );

  // TODO: Remove this once Users page uses the library data table version
  const actionOptions = actionMenuOptions
    ?.filter((option) => option)
    .map((option) => {
      const { icon, text, targetFunction, id } = option ?? {};
      return new MenuOptionSpec(text, icon, targetFunction, id);
    });

  return (
    <div className="trainee-users-tab">
      <div className="trainee-users-tab-header-section">
        <SearchBox
          placeholder={"Search"}
          query={nameSearch.queryData}
          onSearchTermChange={onNameTermChange}
          onResultSelected={onResultSelected}
          customOptionLabel={(r) =>
            `${r?.lastName}, ${r?.firstName} (${r?.username})`
          }
          className={"trainee-users-search"}
        ></SearchBox>
        {rosteringEnabled && (
          <Button
            icon={["fa-regular", "clipboard-list-check"]}
            onClick={() => setOpenRosterModal(true)}
            size="small"
          >
            Roster
          </Button>
        )}
      </div>
      <div className="trainees-table-container ">
        <DataTable
          columns={columns}
          data={stateTrainees}
          className={clsx(
            "trainee-users-table",
            className,
            bottomBarOptions && "selection"
          )}
          keyFields={["id"]}
          keyPrefix="trainee-users"
          defaultSortPropName={"isAnonymous"}
          defaultSortDirection={SortDirection.ASCENDING}
          zeroStateMessage={zeroStateMessage}
          responsiveStyles={responsiveStyles}
          bottomBarOptions={bottomBarOptions}
          tableContentName={{ singular: "user", plural: "users" }}
          actionMenuOptions={actionOptions}
          virtualRowEnabled
          isMenuActionVisible={disableEditsToAnonUsers}
        />
      </div>
      {rosteringEnabled && (
        <RosterModal
          onClose={() => setOpenRosterModal(false)}
          show={openRosterModal}
          responsiveStyles={responsiveStyles}
        ></RosterModal>
      )}
    </div>
  );
}
