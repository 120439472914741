import { parseJwt } from "../../lib/browser-utils";
import { thunk, action, computed } from "easy-peasy";
import { getRoleFeatures } from "../../models/role";
import { Product } from "../../models";
import redirect from "../../views/redirect";

export default ({
  accountService,
  currentUserCache,
  tokenCache,
  appSettingsCache,
  proProfsCache,
  toggles,
}) => {
  const cacheUser = currentUserCache.get();
  const proProfsCacheData = proProfsCache.get();
  const { impersonation } = cacheUser ?? {};

  return {
    error: false,
    currentUser: cacheUser,
    emailSent: false,
    validToken: false,
    verifyingToken: true,
    token: undefined,
    impersonating: !!impersonation,
    resolving: false,
    bulkUploadId: undefined,
    proProfsSettings: proProfsCacheData,
    showIntegrations: false,
    selfSignupData: undefined,

    isLoggedIn: computed((state) => {
      return state.currentUser?.exp > Math.floor(Date.now() / 1000);
    }),

    role: computed((state) => {
      const token = state.currentUser && parseJwt(state.currentUser?.token);
      return token?.act;
    }),

    products: computed((state) => {
      const { products } = state.currentUser ?? {};
      const productsData = toggles.ceEnabled
        ? products
        : products?.filter((p) => p !== Product.CE);

      return productsData;
    }),

    features: computed((state) => {
      return getRoleFeatures(state.role);
    }),

    setCurrentUser: action((state, user) => {
      state.currentUser = user;
      state.error = false;
    }),

    setEmailSent: action((state, sent) => {
      state.emailSent = sent;
    }),

    setValidToken: action((state, payload) => {
      state.validToken = payload.valid;
      state.verifyingToken = false;
      state.token = payload.token;
    }),

    setError: action((state, error) => {
      state.error = error;
    }),

    setResolving: action((state, resolving) => {
      state.resolving = resolving;
    }),

    setBulkUploadId: action((state, id) => {
      state.bulkUploadId = id;
    }),

    setShowIntegrations: action((state, value) => {
      state.showIntegrations = value;
    }),

  
    login: thunk(async (actions, payload) => {
      try {
        actions.setError(false);
        actions.setResolving(true);

        const cacheUser = currentUserCache.get();

        if (cacheUser) {
          actions.setCurrentUser(cacheUser);
          actions.setResolving(false);
          return cacheUser;
        }
        const user = await accountService.login(
          payload.username,
          payload.password
        );
        const currentToken = parseJwt(user.token);
        const currentUser = { exp: currentToken.exp, ...user };
        currentUser.isSso = false;
        console.log("isSso false");
        tokenCache.set(user.token, { exp: currentToken.exp });
        currentUserCache.set(currentUser, { exp: currentToken.exp });
        actions.setCurrentUser(currentUser);
        actions.setShowIntegrations(currentUser?.integrations);

      } catch (error) {
        console.error(error);
        tokenCache.remove();
        currentUserCache.remove();
        appSettingsCache.remove();
        actions.setError(error);
      }
      actions.setResolving(false);
    }),

    ssoLogin: thunk(async (actions, token) => {
      try {

        const currentToken = parseJwt(token);
        tokenCache.set(token, { exp: currentToken.exp });
        const user = await accountService.ssoLogin();

        const currentUser = { exp: currentToken.exp, ...user };
        currentUser.token = token;
        currentUser.isSso = true;
        console.log("isSso true");
        currentUserCache.set(currentUser, { exp: currentToken.exp });
        console.log(currentUserCache.get());
        actions.setCurrentUser(currentUser); 
        actions.setShowIntegrations(currentUser?.integrations);

      } catch (error) {
        console.error(error);
        tokenCache.remove();
        currentUserCache.remove();
        appSettingsCache.remove();
        actions.setError(error);
      }

      console.log(JSON.stringify(currentUserCache.get()), "end ssologin");
    }),

    impersonate: thunk(async (actions, token) => {
      try {
        actions.clearStore();
        actions.setImpersonating();
        const currentToken = parseJwt(token);
        tokenCache.set(token, { exp: currentToken.exp });
        const user = await accountService.impersonate();

        const currentUser = { exp: currentToken.exp, ...user };
        currentUser.impersonation = true;
        currentUser.token = token;
        currentUserCache.set(currentUser, { exp: currentToken.exp });
        actions.setCurrentUser(currentUser);      
      } catch (error) {
        console.error(error);
        tokenCache.remove();
        currentUserCache.remove();
        appSettingsCache.remove();
        actions.setError(error);
      }
    }),

    setImpersonating: action((state) => {
      state.impersonating = true;
    }),

    clearStore: action((state) => {
      state.currentUser = undefined;
      state.impersonating = false;
      currentUserCache.remove();
      tokenCache.remove();
      appSettingsCache.remove();
    }),

    logout: thunk(async (actions, payload, { getStoreActions }) => {

      console.log("aldff", currentUserCache.get());
      const currentUser = currentUserCache.get();
      const { isSso, organizationCode } = currentUser;

      if (isSso) {
        await accountService.samlLogout(organizationCode);
      }
      
      const { organization } = getStoreActions();
      actions.clearStore();
      organization.clearStore();

      const proProfsSettings = proProfsCache.get();
      const ssoSource = proProfsSettings?.ssoSourceUrl;
      let redirectUrl = null;
    
      if (toggles.proProfsEnabled && payload && payload.logOutRequested) {
        actions.setLogOutRequested(true);
      }
      if (toggles.proProfsEnabled && ssoSource)
        if (!isSso) {
          actions.doProProfsLogout(ssoSource.url);
        }
        else {
          redirectUrl = ssoSource.url;
        }

      if (isSso) {
        console.log("ssologout");
        console.log(JSON.stringify(organizationCode));
        await accountService.ssoLogout(redirectUrl);
      }

    }),

    setLogOutRequested: action((state, logOutRequested) => {
      state.proProfsSettings = {
        redirectUrl: undefined,
        appLogoutRequested: logOutRequested,
        ssoSourceUrl: undefined,
      };
      proProfsCache.set({
        redirectUrl: undefined,
        appLogoutRequested: logOutRequested,
        ssoSourceUrl: undefined,
      });
    }),

    clearLogOutRequested: action((state) => {
      state.proProfsSettings = {
        ...state.proProfsSettings,
        appLogoutRequested: undefined,
      };
      proProfsCache.set({
        ...state.proProfsSettings,
        appLogoutRequested: undefined,
      });
    }),

    sendResetPassword: thunk(async (actions, payload) => {
      actions.setResolving(true);
      try {
        await accountService.sendResetPassword(payload);
        actions.setEmailSent(true);
      } catch (error) {
        console.error(error);
        actions.setEmailSent(true);
      }
      actions.setResolving(false);
    }),

    validateResetPassword: thunk(async (actions, payload) => {
      actions.setResolving(true);
      try {
        await accountService.validateResetPassword(
          payload.email,
          payload.token
        );
        actions.setValidToken({ valid: true, ...payload });
      } catch (error) {
        actions.setValidToken({ valid: false, ...payload });
        console.error(error);
      }
      actions.setResolving(false);
    }),

    resetPassword: thunk(async (actions, payload, { getState }) => {
      const { token } = getState();
      try {
        await accountService.resetPassword(token, payload.password);
      } catch (error) {
        console.error(error);
      }
    }),

    setRedirectUrl: action((state, url) => {
      state.proProfsSettings = { ...state.proProfsSettings, redirectUrl: url };
      proProfsCache.set({ ...state.proProfsSettings, redirectUrl: url });
    }),

    setSsoSourceUrl: action((state, url) => {
      state.proProfsSettings = {
        ...state.proProfsSettings,
        ssoSourceUrl: url,
      };
      proProfsCache.set({ ...state.proProfsSettings, ssoSourceUrl: url });
    }),

    doProProfsLogout: action((state, url) => {
      window.location.href = `${url}/user/auth/logout`;
    }),

    doProProfsAuthorization: thunk(async (actions, payload) => {
      try {
        actions.setError(false);
        actions.setResolving(true);
        await accountService
          .proprofsAuth({
            userEmail: payload.email,
            sourceDomain: payload.sourceDomain,
          })
          .then((response) => {
            actions.setRedirectUrl(response);
          });
      } catch (error) {
        console.error(error);
        actions.setError(error);
      }
      actions.setResolving(false);
    }),

    selfSignup: thunk(async (actions, token) => {
      try {
        actions.clearStore();
        const currentToken = parseJwt(token);
        tokenCache.set(token, { exp: currentToken.exp });
        const response = await accountService.verify_self_signup();
        actions.setSelfSignupData(response);
      } catch (error) {
        actions.setSelfSignupData();
        console.error(error);
        tokenCache.remove();
        appSettingsCache.remove();
        actions.setError(error);
      }
    }),

    setSelfSignupData: action((state, payload) => {
      state.selfSignupData = payload;
    })

  };
};
