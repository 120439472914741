import React from "react";
import { SlashCircleIcon } from "@transfr-inc/dashboard-components";
import { ShortcutAction } from "../../../../components/shortcuts";

import "./classroom.mission-control.tab.scss";

export const ClassroomMissionControlTab = ({ classroom }) => {
  console.log({ classroom });
  const data = [];

  const zeroState = (
    <ShortcutAction className="no-data-message" disabled>
      <SlashCircleIcon></SlashCircleIcon>
      {
        "No users to display, please wait until there are live users in the classroom"
      }
    </ShortcutAction>
  );

  return (
    <div className="mission-control-main-container">
      {!data?.length ? zeroState : <div>Mission Control</div>}
    </div>
  );
};
