import React from "react";
import clsx from "clsx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Link,
  OverflowText,
  PlaceholderProducts,
} from "@transfr-inc/dashboard-components";

import "./shortcut.scss";

export default ({ title, subtitle, products, to, className }) => {
  return (
    <Link className={clsx("shortcut", className)} to={to} underline={false}>
      <div className="content">
        <div className="description">
          <OverflowText className={"title"} text={title}>
            {title}
          </OverflowText>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div className="placeholders">
          {products && (
            <PlaceholderProducts
              products={products.sort()}
            ></PlaceholderProducts>
          )}
        </div>
      </div>
      <div>
        <FontAwesomeIcon icon="fa-light fa-chevron-right" />
      </div>
    </Link>
  );
};
