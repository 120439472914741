import React, { useState, useRef, useEffect, useCallback } from "react";

import { useStoreActions, useStoreState } from "easy-peasy";

import {
  PageLayout,
  PageHeader,
} from "@transfr-inc/dashboard-components/layouts";

import {
  Loader,
  Notification,
  NotificationType,
} from "@transfr-inc/dashboard-components";

import { useApiRequest } from "../../../lib/http-client";
import container from "../../../container";

import { ZeroStateMessage } from "./zero-state-message";
import IntegrationBlock from "./components/integration-block";
import { AddIntegrationModal } from "../../../components/modals";

import "./index.scss";

export default function IntegrationsList({ responsiveStyles = {} }) {
  const { integrationService } = container;
  const { getIntegrations } = useStoreActions((store) => store.organization);
  const [loading, setLoading] = useState();
  const [stateNotification, setStateNotification] = useState();
  const [showAddIntegrationModal, setShowAddIntegrationModal] = useState();
  const [addIntegrationFailed, setAddIntegrationFailed] = useState();
  const [newIntegration, setNewIntegration] = useState();
  const [connectionId, setConnectionId] = useState();
  const [lastRunDate, setLastRunDate] = useState("");
  const [lastRunId, setLastRunId] = useState();
  const [authUrl, setAuthUrl] = useState();
  const intervalIdRef = useRef();
  const oauthWindowRef = useRef();
  const syncIntervalRef = useRef();
  const checkWindowIsClosedRef = useRef();

  const { currentUser } = useStoreState((store) => store.account);
  const { id, organizationCode } = currentUser;

  const { response: integrationsList = [] } = useApiRequest(() => {
    return integrationService.getIntegrationList();
  });

  const {
    loading: connectionListLoading,
    response: connectionList = [],
    sendRequest: requestConnectionList,
  } = useApiRequest(() =>
    integrationService
      .getConnectionsList(organizationCode)
      .then(async (response) => {
        for (const connection of response) {
          if (connection.lastRunStatus !== "Pending") {
            const errorsResponse = await getSyncErrors(
              connection.id,
              connection.lastRunSyncJobId
            );
            connection.errors = errorsResponse;
          }
        }
        return response;
      })
  );

  const getSyncErrors = async (connectionId, jobId) => {
    try {
      const res = await integrationService.getSyncErrors(connectionId, jobId);
      return res;
    } catch {
      launchNotification({
        type: NotificationType.error,
        message: `An error ocurred while fetching sync errors for your connection.`,
      });
    }
  };

  const pageHeader = (
    <PageHeader
      title={"Integrations"}
      badge={connectionList?.length || "0"}
      subTitle={"Quickly pull in data from third party tools."}
    />
  );

  const onSetShowIntegration = async (value) => {
    try {
      const providerInfo = await integrationService.getProviderData(value.id);
      setNewIntegration(providerInfo);
      setShowAddIntegrationModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const zeroStateMessage = (
    <ZeroStateMessage
      integrations={integrationsList}
      onActionClicked={onSetShowIntegration}
    ></ZeroStateMessage>
  );

  const launchNotification = async (notification) => {
    setStateNotification(notification);
    setTimeout(() => {
      setStateNotification();
    }, 10000);
  };

  const onAddIntegration = async (data, providerId) => {
    setShowAddIntegrationModal();
    setLoading(true);
    try {
      const connectionInfo =
        await integrationService.createIntegrationConnection(
          providerId,
          organizationCode,
          id,
          data
        );
      setConnectionId(connectionInfo);

      if (connectionInfo) {
        setAddIntegrationFailed();
        const authUrl = await integrationService.getAuthUrl(
          providerId,
          connectionInfo
        );
        const left = window.screen.width / 2 - 320;
        const top = window.screen.height / 2 - 300;
        oauthWindowRef.current = window.open(
          authUrl,
          "_blank",
          `width=650, height=600, top=${top}, left=${left}`
        );
        checkWindowClosed();
        setAuthUrl(authUrl);
      }
    } catch (error) {
      console.error("ERROR:", error);
      setLoading();
      setAddIntegrationFailed(true);
      setShowAddIntegrationModal(true);
    }
  };

  const checkWindowClosed = () => {
    checkWindowIsClosedRef.current = setInterval(() => {
      if (oauthWindowRef.current?.closed) {
        clearInterval(checkWindowIsClosedRef.current);
        onWindowClosed();
      }
    }, 500);
  };

  const onWindowClosed = () => {
    console.log("OAuth window was closed.");
    setAuthUrl();
    launchNotification({
      type: NotificationType.error,
      message: `An error occurred while adding new integration. Please try again.`,
      icon: ["fa-solid", "triangle-exclamation"],
    });
    setLoading(false);
  };

  const clearProviderInterval = useCallback(() => {
    setLastRunDate();
    setLastRunId();
    if (syncIntervalRef.current) {
      clearInterval(syncIntervalRef.current);
      syncIntervalRef.current = undefined;
    }
    setLoading();
  }, []);

  const checkLastSync = useCallback(async () => {
    try {
      const list = await integrationService.getConnectionsList(
        organizationCode
      );
      const refreshedProvider = list.find(
        (connection) => connection.id === lastRunId
      );
      console.log("syncIntervalRef.current:", syncIntervalRef.current);
      if (refreshedProvider.lastRunDate !== lastRunDate) {
        if (refreshedProvider.lastRunStatus === "Failed") {
          launchNotification({
            type: NotificationType.error,
            message: `An error occurred during your last update. Please try again.`,
            icon: ["fa-solid", "triangle-exclamation"],
          });
        } else if (refreshedProvider.lastRunStatus === "Success") {
          launchNotification({
            type: NotificationType.success,
            message: `Your integration has been updated.`,
            icon: ["fa-solid", "circle-check"],
          });
        }
        clearProviderInterval();
      }
    } catch {
      clearProviderInterval();
    }
  }, [lastRunDate, lastRunId, organizationCode, clearProviderInterval]);

  useEffect(() => {
    if (lastRunDate && lastRunId && !syncIntervalRef.current) {
      syncIntervalRef.current = setInterval(() => {
        checkLastSync();
      }, 5000);

      const timeoutId = setTimeout(() => {
        if (syncIntervalRef.current) {
          clearInterval(syncIntervalRef.current);
          syncIntervalRef.current = undefined;
        }
      }, 90000);

      return () => {
        if (syncIntervalRef.current) {
          clearInterval(syncIntervalRef.current);
          syncIntervalRef.current = undefined;
        }
        clearTimeout(timeoutId);
      };
    }
  }, [lastRunDate, lastRunId, checkLastSync]);

  const checkOauthStatus = async () => {
    try {
      const status = await integrationService.getConnectionStatus(connectionId);
      if (status === "active") {
        setAuthUrl();
        launchNotification({
          type: NotificationType.success,
          message: `Your ${newIntegration.name} account has successfully connected with Transfr. Please wait a few minutes while we import your remaining data.`,
          icon: ["fa-solid", "circle-check"],
        });
        requestConnectionList();
        getIntegrations({ orgCode: organizationCode });
        setLoading();
        if (oauthWindowRef.current) {
          oauthWindowRef.current.close();
          oauthWindowRef.current = undefined;
        }
        clearInterval(intervalIdRef.current);
      } else if (status === "failed") {
        setAuthUrl();
        launchNotification({
          type: NotificationType.error,
          message: `An error ocurred while adding new integration. Please try again.`,
          icon: ["fa-solid", "triangle-exclamation"],
        });
        setLoading();
        if (oauthWindowRef.current) {
          oauthWindowRef.current.close();
          oauthWindowRef.current = undefined;
        }
        clearInterval(intervalIdRef.current);
      } else if (status === "pending") {
        return;
      }
    } catch (error) {
      console.error("ERROR:", error);
      setLoading();
      clearInterval(intervalIdRef.current);
    }
  };

  useEffect(() => {
    if (authUrl) {
      intervalIdRef.current = setInterval(() => {
        checkOauthStatus();
      }, 1500);

      setTimeout(() => {
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
      }, 90000);
    } else {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    }
    () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, [authUrl, intervalIdRef.current]);

  const handleAddIntegrationCancel = () => {
    setShowAddIntegrationModal();
    setNewIntegration();
  };

  if (loading || connectionListLoading) {
    return (
      <PageLayout
        className="integrations-list-page"
        responsiveStyles={responsiveStyles}
        header={pageHeader}
      >
        {(loading || connectionListLoading) && <Loader overlay></Loader>}
      </PageLayout>
    );
  }

  const refresh = async (lastRunDate, lastRunId) => {
    if (lastRunDate && lastRunId) {
      setLastRunDate(lastRunDate);
      setLastRunId(lastRunId);
      setLoading(true);
    }

    requestConnectionList();
    getIntegrations({ orgCode: organizationCode });
  };

  return (
    <PageLayout
      className="integrations-list-page"
      responsiveStyles={responsiveStyles}
      header={pageHeader}
    >
      <div className="integrations-table-container">
        {connectionList.length === 0
          ? zeroStateMessage
          : connectionList.map((integration, i) => (
              <IntegrationBlock
                key={`connection-${i}`}
                integration={integration}
                launchNotification={launchNotification}
                refresh={refresh}
                loading={loading}
              />
            ))}
      </div>
      <div className="notification-container success-notification">
        {stateNotification && (
          <Notification
            type={stateNotification.type}
            icon={stateNotification.icon}
            onClose={() => {
              setStateNotification();
            }}
            closable
          >
            {stateNotification.message}
          </Notification>
        )}
      </div>
      {showAddIntegrationModal && (
        <AddIntegrationModal
          onCancel={handleAddIntegrationCancel}
          open={showAddIntegrationModal}
          onAddIntegration={onAddIntegration}
          integration={newIntegration}
          addIntegrationFailed={addIntegrationFailed}
        />
      )}
    </PageLayout>
  );
}
