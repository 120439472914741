import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import classroomHeader from "../../../../public/images/classroom-header.jpg";
import container from "../../../container";
import { classroomUrlMap } from "../../../lib/classroom-url-map";
import { useApiRequest } from "../../../lib/http-client";

import {
  Shortcut,
  Shortcuts,
  ShortcutAction,
  ShortcutsHeader,
  ShortcutsList,
  ShortcutsTally,
  ShortcutsTitle,
} from "../../../components/shortcuts";
import { ClassroomRoute, RootPath } from "../../../lib/routes";

function getClassroomsStats(data, userCount) {
  const hasData = data?.length > 0;
  return [
    {
      description: "Classrooms",
      value: hasData ? data.length : "-",
    },
    {
      description: "Instructors",
      value: userCount
        ? // eslint-disable-next-line unicorn/no-array-reduce
          userCount.totalInstructors
        : "-",
    },
    {
      description: "Trainees",
      value: userCount
        ? // eslint-disable-next-line unicorn/no-array-reduce
          userCount.totalTrainees
        : "-",
    },
  ];
}

export function ClassroomsShortcuts({ classrooms, orgCode }) {
  const { url } = useRouteMatch(RootPath);
  const history = useHistory();
  const classroomPath = `${url}${ClassroomRoute.path}`;
  const { userService } = container;

  const { response: userCount, sendRequest: getUsersCount } = useApiRequest(
    () => userService.getTraineeInstructorCountForOrg(orgCode),
    false
  );

  const [classroomsStats, setClassroomsStats] = useState(
    getClassroomsStats(classrooms, userCount),
    false
  );

  useEffect(() => {
    orgCode && getUsersCount();
  }, [orgCode]);

  useEffect(() => {
    setClassroomsStats(getClassroomsStats(classrooms, userCount));
  }, [classrooms, userCount]);

  const onManageClassroomsClick = () => {
    history.push(classroomPath);
  };

  return (
    <Shortcuts className="classrooms-shortcuts-container">
      <ShortcutsHeader
        image={classroomHeader}
        icon="fa-light fa-screen-users"
      ></ShortcutsHeader>
      <ShortcutsTitle text="Classrooms"></ShortcutsTitle>
      <ShortcutsTally columns={classroomsStats}></ShortcutsTally>
      <ShortcutsList>
        {classrooms.map((d, i) => (
          <Shortcut
            key={i}
            to={{
              pathname: `${classroomPath}/${classroomUrlMap(d.products)}/${
                d.code
              }`,
              state: {
                classroomId: d.classroomId,
                breadcrumbLabel: "Dashboard",
              },
            }}
            products={d.products}
            title={d.name}
            subtitle={`${d.totalTrainees} Users`}
          ></Shortcut>
        ))}
      </ShortcutsList>
      <div className="manage-action">
        <ShortcutAction onClick={onManageClassroomsClick}>
          Manage Classrooms
        </ShortcutAction>
      </div>
    </Shortcuts>
  );
}
